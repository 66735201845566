<template>
    <styled-card
        id="metric-funnel"
        class="flex ma-3 xs12"
        hide-toggle>
        <template #heading>
            Meta Metric Funnel
        </template>
        <template #action-buttons>
            <action-button
                v-if="!userIsClient"
                icon="info"
                @click="$emit('show-info')">
                Info
            </action-button>
        </template>
        <loader v-if="loadingAdInsights" />
        <div
            v-else-if="hasInsights"
            class="metrics">
            <div class="left-pane">
                <advertising-funnel
                    :metric="metric"
                    :ad-insights="insights"
                    :offline-event-upload-required="false"
                    @changeMetric="changeMetric"
                    @updateMetrics="updateMetrics" />
                <offline-events />
            </div>
            <div
                class="
                    right-pane">
                <chart
                    :metric="metric"
                    :metric-series="insightsIntervals"
                    :insights="insights"
                    :available-metrics="availableMetrics"
                    :show-sales="canViewOfflinePurchaseAttribution"
                    @changeMetric="changeMetric" />
            </div>
        </div>
        <div
            v-else
            class="no-data">
            <p>No Data Available for the selected dealer &amp; date range</p>
        </div>
    </styled-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Loader from '@/components/globals/Loader';
import StyledCard from '@/components/globals/StyledCard';
import ActionButton from '@/components/globals/ActionButton';
import AdvertisingFunnel from './funnels/AdvertisingFunnel';
import OfflineEvents from './OfflineEvents';
import Chart from './Chart';

export default {
    name: 'MetricFunnel',
    components: {
        StyledCard,
        ActionButton,
        Loader,
        AdvertisingFunnel,
        OfflineEvents,
        Chart
    },
    data() {
        return {
            selectedTab: null,
            metric: {},
            availableMetrics: [],
        };
    },
    computed: {
        ...mapState({
            currentDealerId: state => state.dealer.currentDealerId,
            currentDealer: state => state.dealer.currentDealer,
            user: state => state.user.user,
            dateRange: state => state.metrics.dateRange
        }),
        ...mapGetters('metrics/facebook/ads', {
            canViewOfflinePurchaseAttribution: 'canViewOfflinePurchaseAttribution',
            insightsTotals: 'metricsTotalsFormatted',
            insightsTotalsComparison: 'metricsTotalsComparisonFormatted',
            loadingAdInsights: 'metricsLoading',
            insightsIntervals: 'metricsIntervalsFormatted',
            insightsTotalsPreviousMonth: 'metricsTotalsPreviousMonthFormatted',
            insightsTotalsPrevious90Days: 'metricsTotalsPrevious90DaysFormatted',
            hasInsights: 'hasMetrics'
        }),
        ...mapGetters([
            'dealerProducts',
            'userIsClient'
        ]),
        insights() {
            return {
                average90Day: this.insightsTotalsPrevious90Days,
                previousMonth: this.insightsTotalsPreviousMonth,
                previousPeriod: this.insightsTotalsComparison,
                selected: this.insightsTotals,
                series: this.insightsIntervals
            };
        },
    },
    methods: {
        updateTab(tab) {
            this.activeTab = tab;
        },
        updateMetrics(metrics) {
            this.availableMetrics = metrics;
        },
        changeMetric(metric) {
            this.metric = metric;
        }
    }
};
</script>

<style lang="scss" scoped>
$breakpoint: 1330px;
.metrics {
    display: flex;
    flex-direction: column;

    @media (min-width: $breakpoint) {
        flex-direction: row;
    }

    .left-pane {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #d8d8d8;
        flex: 1;
        width: 100%;

        @media (min-width: $breakpoint) {
            border-bottom: none;
            border-right: 1px solid #d8d8d8;
            width: 60%;
        }
    }
    .right-pane {
        width: 100%;
        @media (min-width: $breakpoint) {
            width: 40%;
        }
    }
}
.no-data {
    padding: 30px;
    text-align: center;
    font-style: italic;
}
</style>
