<template>
    <styled-card
        class="mb-4 gray-icons flex">
        <template #heading>
            <div class="layout align-center">
                <span>
                    Offline Performance
                </span>
            </div>
        </template>

        <template #action-buttons>
            <v-select
                v-model="eventType"
                :items="eventTypes"
                item-text="label"
                item-value="key"
                label="Event Type"
                class="styled-field mr-4"
                return-object />
            <action-button
                icon="add"
                :to="{
                    name: 'dealer-offline-events',
                    params: {
                        dealer_id: dealerId
                    }
                }">
                Upload Events
            </action-button>
        </template>

        <div
            v-if="error || !dealerFacebookOfflineEventSetAnnotation"
            class="text-xs-center ma-5">
            <div
                v-if="error"
                class="red--text">
                {{ error }}
            </div>
            <div
                v-else-if="!dealerFacebookOfflineEventSetAnnotation"
                class="red--text">
                No offline event set has been configured on this account.
                Please contact support to resolve this issue
            </div>
        </div>

        <div
            v-if="!totalCurrentOfflinePurchaseEvents"
            class="pa-5 text-xs-center">
            <h3 v-if="lastEventUploadDate">
                You haven't uploaded {{ eventType.name | camelCaseToWords | lowercase }}
                events since {{ lastEvent }}.
            </h3>
            <h3 v-else>
                There is not enough available offline data to calculate
                {{ eventType.name | camelCaseToWords | lowercase }}
                event attribution data.
            </h3>
            <p>
                Please
                <router-link
                    :to="{
                        name: 'dealer-offline-events',
                        params: {
                            dealer_id: dealerId
                        }

                    }">
                    <span>upload {{ eventType.name | camelCaseToWords | lowercase }} events</span>
                </router-link>
                up to {{ endDate }} to view sales attribution for this period.
            </p>

            <styled-button
                green
                :to="{
                    name: 'dealer-offline-events',
                    params: {
                        dealer_id: dealerId
                    }
                }">
                UPLOAD EVENTS
            </styled-button>
        </div>

        <div
            v-else
            class="pa-5">
            <div class="layout row wrap align-end pb-4 px-4">
                <div class="flex shrink">
                    <market-stats
                        :style="{
                            width: '140px',
                            padding: '0 0 30px'
                        }"
                        :title="totalLabel"
                        :metric="totalEvents"
                        decreased-size>
                        <styled-loader
                            v-if="loadingEvents"
                            size="20"
                            class="mt-2" />
                    </market-stats>
                </div>
                <div class="flex grow">
                    <div class="layout row wrap align-end pb-2">
                        <div class="flex md2 xs2 text-center pr-2">
                            <img src="/img/triangle.png">
                        </div>
                        <div class="flex md8 xs8">
                            <table class="purchase-window">
                                <thead>
                                    <tr>
                                        <th />
                                        <th colspan="3">
                                            <h3>Attribution Window</h3>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th />
                                        <th :style="{ width: '25%' }">
                                            1 DAY
                                        </th>
                                        <th :style="{ width: '25%' }">
                                            7 DAYS
                                        </th>
                                        <th :style="{ width: '25%' }">
                                            28 DAYS
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Viewed Ad and did not click</td>
                                        <td
                                            :class="{
                                                'active-window': attribution.offline.view == '1d'
                                            }">
                                            <span>{{ attributionMetrics['1d_view'].formatted }}</span>
                                        </td>
                                        <td
                                            :class="{
                                                'active-window': attribution.offline.view == '7d'
                                            }">
                                            <span>{{ attributionMetrics['7d_view'].formatted }}</span>
                                        </td>
                                        <td
                                            :class="{
                                                'active-window': attribution.offline.view == '28d'
                                            }">
                                            <span>{{ attributionMetrics['28d_view'].formatted }}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Viewed and Clicked on Ad</td>
                                        <td
                                            :class="{
                                                'active-window': attribution.offline.click == '1d'
                                            }">
                                            <span>{{ attributionMetrics['1d_click'].formatted }}</span>
                                        </td>
                                        <td
                                            :class="{
                                                'active-window': attribution.offline.click == '7d'
                                            }">
                                            <span>{{ attributionMetrics['7d_click'].formatted }}</span>
                                        </td>
                                        <td
                                            :class="{
                                                'active-window': attribution.offline.click == '28d'
                                            }">
                                            <span>{{ attributionMetrics['28d_click'].formatted }}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="flex md2 xs2 text-center pl-2">
                            <img src="/img/triangle.png">
                        </div>
                    </div>
                </div>
                <div class="flex shrink">
                    <market-stats
                        :style="{
                            width: '140px',
                            padding: '0 0 20px'
                        }"
                        title="EVENTS ATTRIBUTED TO META ADS"
                        :metric="totalAttributedEvents"
                        decreased-size />

                    <market-stats
                        v-if="!hideSpend"
                        :style="{
                            width: '140px',
                            padding: 0
                        }"
                        title="COST PER"
                        :metric-string="costPerEvent"
                        decreased-size />
                </div>
            </div>
        </div>
    </styled-card>
</template>

<script>
import StyledButton from '@/components/globals/StyledButton';
import StyledLoader from '@/components/globals/StyledLoader';
import StyledCard from '@/components/globals/StyledCard';
import MarketStats from '@/components/globals/MarketStats';
import ActionButton from '@/components/globals/ActionButton';
import { FACEBOOK_OFFLINE_EVENT_TYPES, FACEBOOK_ATTRIBUTION_WINDOWS } from '@/helpers/globals';
import getNumberFormats from '@/helpers/numberFormats';
import numeral from 'numeral';
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';

export default {
    name: 'OfflineEventsCard',
    components: {
        StyledButton,
        StyledLoader,
        ActionButton,
        StyledCard,
        MarketStats
    },
    props: {
        dealerId: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
            error: null,
            numberFormats: getNumberFormats(),
            eventType: null,
            loadingEvents: false,
        };
    },
    computed: {
        ...mapState({
            lastEventUploadDate: state => state.metrics.facebook.ads.lastEventUploadDate,
            dateRange: (state) => state.metrics.dateRange,
            attribution: (state) => state.metrics.facebook.ads.attribution,
        }),
        ...mapGetters(['dealerFacebookOfflineEventSetAnnotation']),
        ...mapGetters('metrics/facebook/ads', {
            metricTotals: 'metricsTotalsFormatted',
            totalCurrentOfflinePurchaseEvents: 'totalCurrentOfflinePurchaseEvents',
            offlineEventsByType: 'offlineEventsByType',
        }),
        ...mapGetters({
            hideSpend: 'metrics/hideSpend'
        }),
        clickAttribution() {
            return this.attribution.offline.click + '_click';
        },
        viewAttribution() {
            return this.attribution.offline.view + '_view';
        },
        totalLabel() {
            const event = this.$options.filters.camelCaseToWords(this.eventType.name);
            return `Total Offline ${event} Events`;
        },
        eventTypes() {
            return FACEBOOK_OFFLINE_EVENT_TYPES.map(eventType => {
                const newType = { ...eventType }; // Avoid infinite state updates
                newType.count = this.offlineEventsByType[newType.key] || 0;
                newType.label = `${newType.name} (${newType.count})`;
                return newType;
            });
        },
        attributionMetrics() {
            const metrics = {};
            FACEBOOK_ATTRIBUTION_WINDOWS.forEach(attributionWindow => {
                metrics[attributionWindow.key] = this.metricTotals[`offline_${this.eventType.key}_${attributionWindow.key}`];
            });
            return metrics;
        },
        totalAttributedEvents() {
            return (
                (this.metricTotals[`offline_${this.eventType.key}_${this.clickAttribution}`]?.value || 0)
                +
                (this.metricTotals[`offline_${this.eventType.key}_${this.viewAttribution}`]?.value || 0)
            );
        },
        costPerEvent() {
            const costPer = this.metricTotals.spend.value / this.totalAttributedEvents;

            return isFinite(costPer) ? numeral(costPer).format(this.numberFormats.currency) : '-';
        },
        totalEvents() {
            if (!this.eventType) {
                return 0;
            }

            return this.offlineEventsByType[this.eventType.key] ?? 0;
        },
        canViewOfflineAttribution() {
            if (this.lastEventUploadDate == null) {
                return false;
            }

            const diff = moment(this.dateRange.endDate)
                            .diff(this.lastEventUploadDate, 'days');

            return diff <= 0;
        },
        lastEvent() {
            return moment(this.lastEventUploadDate).format('MM/DD/Y');
        },
        endDate() {
            return moment(this.dateRange.endDate).format('M/D/Y');
        }
    },
    created() {
        this.eventType = this.eventTypes.find(type => type.key === 'purchase');
    }
};
</script>

<style lang="scss" scoped>
.warning-message {
    color: $carnation;
    font-weight: 600;
    text-align: center;
    margin-top: 20px;
}
.purchase-window {
    border-collapse: collapse;
    width: 100%;
    th {
        font-weight: normal;
        font-size: 0.9rem;
        padding-bottom: 10px;
    }
    td {
        color: $light-blue-text;
        font-size: 2rem;
        font-weight: 600;
        border-right: 1px solid $loblolly;
        padding: 0;
        vertical-align: middle;
        text-align: center;
        &:first-child {
            text-transform: uppercase;
            font-size: 0.9rem;
            color: $gray-dark;
            text-align: right;
            font-weight: normal;
            border-right: none;
            overflow: hidden;
            white-space: nowrap;
        }
        &:last-child {
            border-right: none;
        }
        &.active-window {
            span::after {
                content: '';
                position: absolute;
                display: block;
                z-index: -1;
                top: 50%;
                left: 50%;
                height: 54px;
                width: 54px;
                background-color: rgba($light-blue-text, 0.2);
                border-radius: 100%;
                transform: translate(-50%, -50%);
            }
        }
        span {
            position: relative;
            display: inline-block;
            z-index: 10;
            padding: 10px;
        }
    }
    tr:last-child {
        td {
            border-top: 1px solid $loblolly;
        }
    }
}
</style>
